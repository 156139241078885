import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card, Button, Table, message, Select, Space, Typography, Divider, Popconfirm, Spin, Alert
} from 'antd';
import { ArrowLeftOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Title, Text } = Typography;
const { Option } = Select;

const TeacherClassBinding = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState(null);
  const [classes, setClasses] = useState([]);
  const [availableClasses, setAvailableClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTeacherInfo = async () => {
    try {
      const response = await axios.get(`/api/teachers/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        setTeacher(response.data.data);
      } else {
        setError(response.data.message || '获取教师信息失败');
      }
    } catch (error) {
      setError(error.response?.data?.message || '获取教师信息失败');
      console.error('获取教师信息失败:', error);
    }
  };

  const fetchTeacherClasses = async () => {
    try {
      const response = await axios.get(`/api/teacher-classes/teacher/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        setClasses(response.data.data);
      } else {
        setError(response.data.message || '获取教师班级失败');
      }
    } catch (error) {
      setError(error.response?.data?.message || '获取教师班级失败');
      console.error('获取教师班级失败:', error);
    }
  };

  const fetchAvailableClasses = async () => {
    try {
      // 假设有接口可以获取当前教师所在学校的所有班级
      const response = await axios.get(`/api/classes`, {
        params: { schoolId: teacher?.school_id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.data.success) {
        // 过滤已经绑定的班级
        const boundClassIds = classes.map(c => c.id);
        const available = response.data.data.filter(c => !boundClassIds.includes(c.id));
        setAvailableClasses(available);
      } else {
        message.error(response.data.message || '获取可用班级失败');
      }
    } catch (error) {
      message.error(error.response?.data?.message || '获取可用班级失败');
      console.error('获取可用班级失败:', error);
    }
  };

  const loadData = async () => {
    setLoading(true);
    setError(null);
    try {
      await fetchTeacherInfo();
      await fetchTeacherClasses();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError('加载数据失败，请重试');
      console.error('加载数据失败:', error);
    }
  };

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    if (teacher && teacher.school_id) {
      fetchAvailableClasses();
    }
  }, [teacher, classes]);

  const handleBindClass = async () => {
    if (!selectedClassId) {
      message.warning('请选择要绑定的班级');
      return;
    }

    setBtnLoading(true);
    try {
      const response = await axios.post('/api/teacher-classes', {
        teacherId: id,
        classId: selectedClassId
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.success) {
        message.success('班级绑定成功');
        // 刷新班级列表
        fetchTeacherClasses();
        // 重置选择
        setSelectedClassId(null);
      } else {
        message.error(response.data.message || '班级绑定失败');
      }
    } catch (error) {
      message.error(error.response?.data?.message || '班级绑定失败');
      console.error('班级绑定失败:', error);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUnbindClass = async (bindingId) => {
    try {
      const response = await axios.delete(`/api/teacher-classes/${bindingId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.data.success) {
        message.success('班级解绑成功');
        // 刷新班级列表
        fetchTeacherClasses();
      } else {
        message.error(response.data.message || '班级解绑失败');
      }
    } catch (error) {
      message.error(error.response?.data?.message || '班级解绑失败');
      console.error('班级解绑失败:', error);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '班级名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '年级',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'active' ? 'green' : 'red' }}>
          {status === 'active' ? '激活' : '未激活'}
        </span>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="确定要解除与该班级的绑定吗?"
          onConfirm={() => handleUnbindClass(record.binding_id)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} size="small">
            解绑
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '20px' }}>
        <Alert type="error" message={error} banner />
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Button onClick={() => navigate('/teachers')} icon={<ArrowLeftOutlined />}>
            返回教师列表
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Card
        title={
          <div>
            <Button 
              type="link" 
              icon={<ArrowLeftOutlined />} 
              style={{ marginRight: 10, padding: 0 }}
              onClick={() => navigate('/teachers')}
            />
            教师班级绑定管理
          </div>
        }
      >
        {teacher && (
          <div>
            <div style={{ marginBottom: 20 }}>
              <Title level={4}>教师信息</Title>
              <p><Text strong>姓名：</Text> {teacher.name}</p>
              <p><Text strong>学科：</Text> {teacher.subject}</p>
              <p><Text strong>学校：</Text> {teacher.school_name}</p>
            </div>
            
            <Divider />
            
            <div style={{ marginBottom: 20 }}>
              <Title level={4}>绑定新班级</Title>
              <Space>
                <Select
                  placeholder="选择班级"
                  style={{ width: 300 }}
                  value={selectedClassId}
                  onChange={setSelectedClassId}
                >
                  {availableClasses.map(c => (
                    <Option key={c.id} value={c.id}>{`${c.grade} ${c.name}`}</Option>
                  ))}
                </Select>
                <Button 
                  type="primary" 
                  icon={<PlusOutlined />}
                  loading={btnLoading}
                  onClick={handleBindClass}
                >
                  绑定班级
                </Button>
              </Space>
            </div>
            
            <Divider />

            <div>
              <Title level={4}>已绑定班级</Title>
              <Table 
                columns={columns} 
                dataSource={classes} 
                rowKey="id"
                pagination={{ pageSize: 10 }}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default TeacherClassBinding; 