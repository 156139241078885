import React, { useState } from 'react';
import { Card, Form, Input, Button, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SchoolCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/schools', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      message.success('学校创建成功');
      navigate('/schools');
    } catch (err) {
      console.error('创建学校失败:', err);
      setError(err.response?.data?.message || '创建学校失败');
      message.error('创建学校失败');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title="添加学校">
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="学校名称"
            rules={[{ required: true, message: '请输入学校名称' }]}
          >
            <Input placeholder="请输入学校名称" />
          </Form.Item>
          
          <Form.Item
            name="address"
            label="学校地址"
          >
            <Input placeholder="请输入学校地址" />
          </Form.Item>
          
          <Form.Item
            name="contact"
            label="联系人"
          >
            <Input placeholder="请输入联系人姓名" />
          </Form.Item>
          
          <Form.Item
            name="phone"
            label="联系电话"
          >
            <Input placeholder="请输入联系电话" />
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              创建学校
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate('/schools')}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default SchoolCreate; 