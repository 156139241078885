import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Spin, Alert } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { adminAPI } from '../../services/api';

const { Option } = Select;

const AdminEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    fetchAdminDetails();
  }, [id]);

  const fetchAdminDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // 获取管理员详情
      const response = await adminAPI.getById(id);
      if (response.data.success) {
        const adminData = response.data.data;
        form.setFieldsValue({
          username: adminData.username,
          name: adminData.name,
          email: adminData.email,
          schoolId: adminData.school_id
        });
      } else {
        setError(response.data.message || '获取管理员信息失败');
      }
    } catch (err) {
      console.error('获取管理员信息错误:', err);
      setError('获取管理员信息失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await adminAPI.update(id, values);
      if (response.data.success) {
        message.success('管理员信息更新成功');
        navigate('/admins');
      } else {
        message.error(response.data.message || '更新管理员信息失败');
      }
    } catch (err) {
      console.error('更新管理员信息错误:', err);
      message.error('更新管理员信息失败，请稍后再试');
    }
  };

  return (
    <Card title="编辑管理员">
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 24 }} />}
        
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>

          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '请输入姓名' }]}
          >
            <Input placeholder="请输入姓名" />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { type: 'email', message: '请输入有效的邮箱地址' },
              { required: true, message: '请输入邮箱' }
            ]}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            extra="如果不需要修改密码，请留空"
          >
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate('/admins')}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default AdminEdit; 