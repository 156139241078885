import React from 'react';
import { Layout, Menu, Dropdown, Button, Space } from 'antd';
import { UserOutlined, LogoutOutlined, SettingOutlined, DownOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const { Header } = Layout;

const AppHeader = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        <Link to="/profile">个人资料</Link>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <Link to="/profile">设置</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ backgroundColor: '#fff', padding: '0 20px', display: 'flex', justifyContent: 'space-between' }}>
      <div className="logo" style={{ marginRight: '20px' }}>
        <Link to="/" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <h1 style={{ margin: 0, color: '#1890ff', fontSize: '18px' }}>学校广播系统管理平台</h1>
        </Link>
      </div>
      
      <div style={{ marginLeft: 'auto' }}>
        {user && (
          <Dropdown overlay={userMenu} trigger={['click']}>
            <Button type="link">
              <Space>
                <UserOutlined />
                {user.name || user.username}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
      </div>
    </Header>
  );
};

export default AppHeader; 