import React, { useState, useEffect } from 'react';
import { 
  Card, Table, Button, Space, Tag, Input, Drawer, 
  message, Select, Form, Radio, Upload, Popconfirm, 
  DatePicker, Modal, Descriptions, Image, 
  Typography
} from 'antd';
import { 
  PlusOutlined, DeleteOutlined, ReloadOutlined, 
  UploadOutlined, FilterOutlined, SearchOutlined, 
  ClearOutlined, EyeOutlined, FileOutlined,
  SendOutlined
} from '@ant-design/icons';
import { broadcastAPI, classAPI, schoolAPI } from '../../services/api';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea, Search } = Input;
const { RangePicker } = DatePicker;

const BroadcastList = () => {
  const [loading, setLoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [classes, setClasses] = useState([]);
  const [schools, setSchools] = useState([]);
  const [senders, setSenders] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [formData, setFormData] = useState({
    type: 'text',
    content: '',
    displayMode: 'banner',
    targetClass: null,
    targetSchool: null,
    sendMode: 'class' // 'class' 或 'school'
  });
  const [fileList, setFileList] = useState([]);
  const [filters, setFilters] = useState({
    schoolId: null,
    classId: null,
    type: null,
    dateRange: null,
    contentQuery: '',
    senderId: null
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    fetchClasses();
    fetchSchools();
    fetchSenders();
    fetchMessageHistory();
  }, []);

  // 获取班级列表
  const fetchClasses = async () => {
    try {
      setLoading(true);
      const response = await classAPI.getAll();
      if (response.data.success) {
        setClasses(response.data.data);
      }
    } catch (error) {
      console.error('获取班级列表失败:', error);
      message.error('获取班级列表失败');
    } finally {
      setLoading(false);
    }
  };

  // 获取学校列表
  const fetchSchools = async () => {
    try {
      const response = await schoolAPI.getAll();
      if (response.data.success) {
        setSchools(response.data.data);
      }
    } catch (error) {
      console.error('获取学校列表失败:', error);
      message.error('获取学校列表失败');
    }
  };

  // 获取发送者列表
  const fetchSenders = async () => {
    try {
      const response = await broadcastAPI.getSenders();
      if (response.data.success) {
        setSenders(response.data.data);
      }
    } catch (error) {
      console.error('获取发送者列表失败:', error);
      message.error('获取发送者列表失败');
    }
  };

  // 获取消息历史
  const fetchMessageHistory = async (params = {}) => {
    setLoading(true);
    try {
      const response = await broadcastAPI.getMessageHistory(params);
      if (response.data.success) {
        setMessageHistory(response.data.data);
      }
    } catch (error) {
      console.error('获取消息历史失败:', error);
      message.error('获取消息历史失败');
    } finally {
      setLoading(false);
    }
  };

  // 处理快速搜索
  const handleQuickSearch = (value) => {
    setSearchLoading(true);
    const newFilters = { ...filters, contentQuery: value };
    setFilters(newFilters);
    fetchMessageHistory({ contentQuery: value })
      .finally(() => setSearchLoading(false));
  };

  // 处理表格行选择变化
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // 批量删除选中的消息
  const handleBatchDelete = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning('请至少选择一条消息进行删除');
      return;
    }

    setDeleteLoading(true);
    try {
      const promises = selectedRowKeys.map(id => broadcastAPI.deleteMessage(id));
      await Promise.all(promises);
      
      message.success(`成功删除 ${selectedRowKeys.length} 条广播消息`);
      setSelectedRowKeys([]);
      fetchMessageHistory(prepareFilterParams());
    } catch (error) {
      console.error('批量删除消息失败:', error);
      message.error('批量删除消息失败');
    } finally {
      setDeleteLoading(false);
    }
  };

  // 一键删除筛选结果
  const handleDeleteFiltered = async () => {
    if (messageHistory.length === 0) {
      message.warning('没有可删除的消息');
      return;
    }

    setDeleteLoading(true);
    try {
      const messageIds = messageHistory.map(msg => msg.id);
      const promises = messageIds.map(id => broadcastAPI.deleteMessage(id));
      await Promise.all(promises);
      
      message.success(`成功删除 ${messageIds.length} 条广播消息`);
      setSelectedRowKeys([]);
      fetchMessageHistory(prepareFilterParams());
    } catch (error) {
      console.error('批量删除消息失败:', error);
      message.error('批量删除消息失败');
    } finally {
      setDeleteLoading(false);
    }
  };

  // 处理删除消息
  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await broadcastAPI.deleteMessage(messageId);
      if (response.data.success) {
        message.success('成功删除广播消息');
        fetchMessageHistory(prepareFilterParams());
      } else {
        message.error(response.data.message || '删除消息失败');
      }
    } catch (error) {
      console.error('删除消息失败:', error);
      message.error('删除消息失败');
    }
  };

  // 处理筛选条件变化
  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // 打开筛选抽屉
  const showFilterDrawer = () => {
    setFilterDrawerVisible(true);
  };

  // 关闭筛选抽屉
  const closeFilterDrawer = () => {
    setFilterDrawerVisible(false);
  };

  // 准备筛选参数
  const prepareFilterParams = () => {
    const params = {};
    
    if (filters.schoolId) {
      params.schoolId = filters.schoolId;
    }
    
    if (filters.classId) {
      params.classId = filters.classId;
    }
    
    if (filters.type) {
      params.type = filters.type;
    }
    
    if (filters.dateRange && filters.dateRange.length === 2) {
      params.startDate = filters.dateRange[0].format('YYYY-MM-DD');
      params.endDate = filters.dateRange[1].format('YYYY-MM-DD 23:59:59');
    }
    
    if (filters.contentQuery) {
      params.contentQuery = filters.contentQuery;
    }
    
    if (filters.senderId) {
      params.senderId = filters.senderId;
    }
    
    return params;
  };

  // 应用筛选
  const applyFilters = () => {
    const params = prepareFilterParams();
    fetchMessageHistory(params);
    closeFilterDrawer();
  };

  // 重置筛选条件
  const resetFilters = () => {
    setFilters({
      schoolId: null,
      classId: null,
      type: null,
      dateRange: null,
      contentQuery: '',
      senderId: null
    });
    fetchMessageHistory();
    closeFilterDrawer();
  };

  // 打开发送广播抽屉
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  // 关闭抽屉
  const closeDrawer = () => {
    setDrawerVisible(false);
    setFormData({
      type: 'text',
      content: '',
      displayMode: 'banner',
      targetClass: null,
      targetSchool: null,
      sendMode: 'class'
    });
    setFileList([]);
  };

  // 处理表单变化
  const handleFormChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // 处理发送模式变化
  const handleSendModeChange = (e) => {
    const mode = e.target.value;
    handleFormChange('sendMode', mode);
    // 重置目标
    if (mode === 'class') {
      handleFormChange('targetSchool', null);
    } else {
      handleFormChange('targetClass', null);
    }
  };

  // 处理班级选择
  const handleClassChange = (value) => {
    handleFormChange('targetClass', value);
  };

  // 处理学校选择
  const handleSchoolChange = (value) => {
    handleFormChange('targetSchool', value);
  };

  // 文件上传变化
  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  // 发送消息
  const handleSendMessage = async () => {
    // 验证必填项
    if (formData.sendMode === 'class' && !formData.targetClass) {
      message.error('请选择目标班级');
      return;
    }

    if (formData.sendMode === 'school' && !formData.targetSchool) {
      message.error('请选择目标学校');
      return;
    }

    if (!formData.content && formData.type === 'text') {
      message.error('请输入广播内容');
      return;
    }

    setLoading(true);

    try {
      let finalContent = formData.content;

      // 如果是文件类型，先上传文件
      if (formData.type === 'file' && fileList.length > 0) {
        const fileFormData = new FormData();
        fileFormData.append('file', fileList[0].originFileObj);
        const uploadRes = await broadcastAPI.uploadFile(fileFormData);
        
        if (uploadRes.data.success) {
          finalContent = JSON.stringify(uploadRes.data.data);
        } else {
          throw new Error('文件上传失败');
        }
      }

      // 准备消息数据
      const messageData = {
        type: formData.type,
        content: finalContent,
        displayMode: formData.displayMode
      };

      // 根据发送模式设置目标
      if (formData.sendMode === 'class') {
        messageData.targetClass = formData.targetClass;
      } else {
        messageData.targetSchool = formData.targetSchool;
      }

      message.loading({ content: '正在发送广播消息...', key: 'sendMessage' });
      const response = await broadcastAPI.sendMessage(messageData);

      if (response.data.success) {
        message.success({ content: '广播消息发送成功', key: 'sendMessage', duration: 3 });
        closeDrawer();
        fetchMessageHistory(prepareFilterParams());
      } else {
        message.error({ content: response.data.message || '发送失败', key: 'sendMessage', duration: 3 });
      }
    } catch (error) {
      console.error('发送广播消息失败:', error);
      const errorMsg = error.response && error.response.data && error.response.data.message 
        ? error.response.data.message 
        : '发送广播消息失败，请稍后重试';
      message.error({ content: errorMsg, key: 'sendMessage', duration: 3 });
    } finally {
      setLoading(false);
    }
  };

  // 查看广播详情
  const showMessageDetail = (record) => {
    setCurrentMessage(record);
    setDetailVisible(true);
  };

  // 关闭详情模态框
  const closeMessageDetail = () => {
    setDetailVisible(false);
    setCurrentMessage(null);
  };

  // 渲染广播内容
  const renderBroadcastContent = (record) => {
    if (!record) return null;
    
    try {
      if (record.type === 'text') {
        return <Typography.Paragraph>{record.content}</Typography.Paragraph>;
      } else if (record.type === 'file') {
        // 尝试解析文件内容
        try {
          const fileData = JSON.parse(record.content);
          return (
            <div>
              <Typography.Paragraph>
                <FileOutlined /> 文件: {fileData.originalname}
              </Typography.Paragraph>
              <Button type="link" href={fileData.url} target="_blank">
                下载文件
              </Button>
            </div>
          );
        } catch (e) {
          return <Typography.Paragraph>{record.content}</Typography.Paragraph>;
        }
      } else if (record.type === 'image') {
        // 尝试解析图片内容
        try {
          const imageData = JSON.parse(record.content);
          return (
            <div>
              <Image src={imageData.url} alt="广播图片" style={{ maxWidth: '100%' }} />
            </div>
          );
        } catch (e) {
          return <Typography.Paragraph>{record.content}</Typography.Paragraph>;
        }
      } else {
        return <Typography.Paragraph>{record.content}</Typography.Paragraph>;
      }
    } catch (error) {
      return <Typography.Paragraph>{record.content}</Typography.Paragraph>;
    }
  };

  // 行选择配置
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // 表格列定义
  const columns = [
    {
      title: '消息ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (type) => {
        let color = 'blue';
        let text = '文本';
        
        if (type === 'file') {
          color = 'green';
          text = '文件';
        } else if (type === 'image') {
          color = 'purple';
          text = '图像';
        }
        
        return <Tag color={color}>{text}</Tag>;
      }
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      ellipsis: true,
      render: (content, record) => (
        <Button type="link" onClick={() => showMessageDetail(record)}>
          {content && content.length > 20 ? `${content.substring(0, 20)}...` : content || '查看详情'}
        </Button>
      )
    },
    {
      title: '目标',
      dataIndex: 'target_name',
      key: 'target_name',
      render: (_, record) => (
        <Space>
          {record.target_type === 'school' ? 
            <Tag color="purple">全校: {record.school_name}</Tag> : 
            <Tag color="blue">班级: {record.class_name}</Tag>
          }
        </Space>
      )
    },
    {
      title: '发送者',
      key: 'sender',
      render: (_, record) => (
        <Space>
          <Text>{record.sender_name}</Text>
          <Tag color={record.sender_type === 'admin' ? 'blue' : 'green'}>
            {record.sender_type === 'admin' ? '管理员' : '教师'}
          </Tag>
        </Space>
      )
    },
    {
      title: '发送时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (_, record) => (
        <Space>
          <Button 
            type="text"
            icon={<EyeOutlined />}
            size="small"
            onClick={() => showMessageDetail(record)}
          />
          <Popconfirm
            title="确定要删除这条广播消息吗？"
            onConfirm={() => handleDeleteMessage(record.id)}
            okText="是"
            cancelText="否"
          >
            <Button 
              type="text" 
              danger 
              icon={<DeleteOutlined />}
              size="small"
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="broadcast-list">
      <Card
        title={
          <Space>
            <Title level={4}>广播消息管理</Title>
          </Space>
        }
        extra={
          <Space>
            <Button 
              icon={<FilterOutlined />} 
              onClick={showFilterDrawer}
            >
              筛选
            </Button>
            <Button 
              icon={<ReloadOutlined />} 
              onClick={() => fetchMessageHistory(prepareFilterParams())}
              loading={loading}
            >
              刷新
            </Button>
            <Popconfirm
              title="确定要删除所有筛选出的消息吗？"
              onConfirm={handleDeleteFiltered}
              okText="是"
              cancelText="否"
              disabled={messageHistory.length === 0}
            >
              <Button 
                type="primary"
                danger
                icon={<DeleteOutlined />}
                loading={deleteLoading}
                disabled={messageHistory.length === 0}
              >
                一键删除筛选结果
              </Button>
            </Popconfirm>
            <Button 
              type="primary" 
              icon={<PlusOutlined />} 
              onClick={showDrawer}
            >
              发送广播
            </Button>
          </Space>
        }
      >
        <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
          <Popconfirm
            title="确定要删除选中的消息吗？"
            onConfirm={handleBatchDelete}
            okText="是"
            cancelText="否"
            disabled={selectedRowKeys.length === 0}
          >
            <Button 
              danger
              icon={<DeleteOutlined />}
              disabled={selectedRowKeys.length === 0}
              loading={deleteLoading}
            >
              批量删除选中的 {selectedRowKeys.length} 项
            </Button>
          </Popconfirm>
          
          <Search
            placeholder="搜索消息内容"
            allowClear
            enterButton
            loading={searchLoading}
            onSearch={handleQuickSearch}
            style={{ width: 300 }}
          />
        </div>
        <Table 
          rowSelection={rowSelection}
          columns={columns}
          dataSource={messageHistory.map(msg => ({ ...msg, key: msg.id }))}
          loading={loading}
          pagination={{ pageSize: 10 }}
          bordered
        />
      </Card>

      {/* 筛选抽屉 */}
      <Drawer
        title="广播消息筛选"
        width={400}
        onClose={closeFilterDrawer}
        open={filterDrawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={resetFilters} icon={<ClearOutlined />}>重置</Button>
            <Button onClick={closeFilterDrawer}>取消</Button>
            <Button 
              type="primary" 
              onClick={applyFilters} 
              icon={<SearchOutlined />}
            >
              应用筛选
            </Button>
          </Space>
        }
      >
        <Form layout="vertical">
          <Form.Item label="搜索消息内容">
            <Input
              placeholder="输入要搜索的内容"
              value={filters.contentQuery}
              onChange={(e) => handleFilterChange('contentQuery', e.target.value)}
              allowClear
            />
          </Form.Item>
          
          <Form.Item label="按发送者筛选">
            <Select
              placeholder="选择发送者"
              allowClear
              style={{ width: '100%' }}
              value={filters.senderId}
              onChange={(value) => handleFilterChange('senderId', value)}
            >
              {senders.map(sender => (
                <Option key={sender.id} value={sender.id}>
                  {sender.name} ({sender.role})
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <Form.Item label="按学校筛选">
            <Select
              placeholder="选择学校"
              allowClear
              style={{ width: '100%' }}
              value={filters.schoolId}
              onChange={(value) => handleFilterChange('schoolId', value)}
            >
              {schools.map(school => (
                <Option key={school.id} value={school.id}>
                  {school.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <Form.Item label="按班级筛选">
            <Select
              placeholder="选择班级"
              allowClear
              style={{ width: '100%' }}
              value={filters.classId}
              onChange={(value) => handleFilterChange('classId', value)}
            >
              {classes.map(cls => (
                <Option key={cls.id} value={cls.id}>
                  {cls.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <Form.Item label="按消息类型筛选">
            <Select
              placeholder="选择消息类型"
              allowClear
              style={{ width: '100%' }}
              value={filters.type}
              onChange={(value) => handleFilterChange('type', value)}
            >
              <Option value="text">文本消息</Option>
              <Option value="file">文件消息</Option>
              <Option value="image">图像消息</Option>
            </Select>
          </Form.Item>
          
          <Form.Item label="按时间筛选">
            <RangePicker 
              style={{ width: '100%' }}
              value={filters.dateRange}
              onChange={(dates) => handleFilterChange('dateRange', dates)}
            />
          </Form.Item>
        </Form>
      </Drawer>

      {/* 发送广播抽屉 */}
      <Drawer
        title="发送广播消息"
        width={500}
        onClose={closeDrawer}
        open={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={closeDrawer}>取消</Button>
            <Button 
              type="primary" 
              onClick={handleSendMessage} 
              loading={loading}
              icon={<SendOutlined />}
            >
              发送
            </Button>
          </Space>
        }
      >
        <Form layout="vertical">
          <Form.Item 
            label="发送模式" 
            required
          >
            <Radio.Group
              onChange={handleSendModeChange}
              value={formData.sendMode}
            >
              <Radio value="class">指定班级</Radio>
              <Radio value="school">全校发送</Radio>
            </Radio.Group>
          </Form.Item>

          {formData.sendMode === 'class' ? (
            <Form.Item 
              label="目标班级" 
              required 
              tooltip="选择接收广播的班级"
            >
              <Select
                placeholder="选择班级"
                onChange={handleClassChange}
                value={formData.targetClass}
                style={{ width: '100%' }}
              >
                {classes.map(cls => (
                  <Option key={cls.id} value={cls.id}>
                    {cls.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item 
              label="目标学校" 
              required 
              tooltip="选择接收广播的学校"
            >
              <Select
                placeholder="选择学校"
                onChange={handleSchoolChange}
                value={formData.targetSchool}
                style={{ width: '100%' }}
              >
                {schools.map(school => (
                  <Option key={school.id} value={school.id}>
                    {school.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="消息类型">
            <Radio.Group 
              onChange={(e) => handleFormChange('type', e.target.value)}
              value={formData.type}
            >
              <Radio value="text">文本消息</Radio>
              <Radio value="file">文件消息</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="显示模式">
            <Radio.Group 
              onChange={(e) => handleFormChange('displayMode', e.target.value)}
              value={formData.displayMode}
            >
              <Radio value="banner">横幅显示</Radio>
              <Radio value="popup">弹窗显示</Radio>
              <Radio value="fullscreen">全屏显示</Radio>
            </Radio.Group>
          </Form.Item>

          {formData.type === 'text' ? (
            <Form.Item 
              label="广播内容" 
              required
              tooltip="输入要广播的消息内容"
            >
              <TextArea 
                rows={6} 
                value={formData.content}
                onChange={(e) => handleFormChange('content', e.target.value)}
                placeholder="请输入广播内容"
                maxLength={500}
                showCount
              />
            </Form.Item>
          ) : (
            <Form.Item 
              label="上传文件" 
              required
              tooltip="选择要广播的文件"
            >
              <Upload
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleFileChange}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>选择文件</Button>
              </Upload>
            </Form.Item>
          )}
        </Form>
      </Drawer>

      {/* 广播详情模态框 */}
      <Modal
        title="广播详情"
        open={detailVisible}
        onCancel={closeMessageDetail}
        width={700}
        footer={[
          <Button key="close" onClick={closeMessageDetail}>
            关闭
          </Button>
        ]}
      >
        {currentMessage && (
          <div className="broadcast-detail">
            <Descriptions bordered column={1}>
              <Descriptions.Item label="消息ID">{currentMessage.id}</Descriptions.Item>
              <Descriptions.Item label="类型">
                <Tag color={
                  currentMessage.type === 'text' ? 'blue' : 
                  currentMessage.type === 'file' ? 'green' : 'purple'
                }>
                  {currentMessage.type === 'text' ? '文本' : 
                   currentMessage.type === 'file' ? '文件' : '图像'}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="内容">
                {renderBroadcastContent(currentMessage)}
              </Descriptions.Item>
              <Descriptions.Item label="目标">
                {currentMessage.target_type === 'school' ? 
                  <Tag color="purple">全校: {currentMessage.school_name}</Tag> : 
                  <Tag color="blue">班级: {currentMessage.class_name}</Tag>
                }
              </Descriptions.Item>
              <Descriptions.Item label="发送者">
                <Space>
                  <Text>{currentMessage.sender_name}</Text>
                  <Tag color={currentMessage.sender_type === 'admin' ? 'blue' : 'green'}>
                    {currentMessage.sender_type === 'admin' ? '管理员' : '教师'}
                  </Tag>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="发送时间">
                {new Date(currentMessage.created_at).toLocaleString()}
              </Descriptions.Item>
              <Descriptions.Item label="显示模式">
                <Tag color={
                  currentMessage.display_mode === 'banner' ? 'blue' : 
                  currentMessage.display_mode === 'fullscreen' ? 'red' : 'orange'
                }>
                  {currentMessage.display_mode === 'banner' ? '顶部横幅' : 
                   currentMessage.display_mode === 'fullscreen' ? '全屏显示' : '弹窗显示'}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BroadcastList; 