import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Statistic, Typography, Table, Spin, Alert, Progress, List, Badge, Space, Tag, Collapse, Divider, Button } from 'antd';
import { 
  BankOutlined, 
  TeamOutlined, 
  MessageOutlined, 
  RocketOutlined, 
  WifiOutlined,
  AuditOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import { schoolAPI, broadcastAPI, classAPI, teacherAPI } from '../services/api';

const { Title, Text } = Typography;
const { Panel } = Collapse;

// 自定义样式
const styles = {
  expandedCardContainer: {
    width: '100%',
    marginBottom: 16
  },
  expandedCard: {
    width: '100%',
    transition: 'all 0.3s'
  },
  normalCardContainer: {
    height: '100%'
  },
  dashboardCardStyle: {
    cursor: 'pointer',
    transition: 'all 0.3s',
    height: '100%'
  }
};

const Dashboard = () => {
  const { user, isSuperAdmin } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [schoolStats, setSchoolStats] = useState(null);
  const [schools, setSchools] = useState([]);
  const [broadcastStats, setBroadcastStats] = useState(null);
  
  // 状态变量，控制哪个卡片展开显示详情
  const [expandedCard, setExpandedCard] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const [detailLoading, setDetailLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // 获取广播系统仪表盘数据
        const broadcastResponse = await broadcastAPI.getDashboardData();
        if (broadcastResponse.data.success) {
          setBroadcastStats(broadcastResponse.data.data);
        }

        if (isSuperAdmin()) {
          // 超级管理员: 获取所有学校
          const response = await schoolAPI.getAll();
          if (response.data.success) {
            setSchools(response.data.data);
          }
        } else if (user?.schoolId) {
          // 学校管理员: 获取自己学校的信息 (不使用不存在的getStats方法)
          try {
            const schoolResponse = await schoolAPI.getById(user.schoolId);
            if (schoolResponse.data.success) {
              const broadcastData = broadcastResponse.data.success ? broadcastResponse.data.data : null;
              setSchoolStats({
                school: schoolResponse.data.data,
                stats: {
                  classCount: broadcastData?.totalClasses || 0,
                  adminCount: 0, // 可以从广播统计获取或默认为0
                  messageCount: broadcastData?.totalMessages || 0
                }
              });
            }
          } catch (err) {
            console.error('获取学校信息失败:', err);
          }
        }
      } catch (err) {
        console.error('获取数据失败:', err);
        setError('获取数据失败，请稍后再试');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user, isSuperAdmin]);

  // 获取详情数据的函数
  const fetchOnlineClassesDetail = async () => {
    setDetailLoading(true);
    try {
      const response = await classAPI.getAll();
      if (response.data.success) {
        // 过滤出在线班级
        const onlineClasses = response.data.data.filter(cls => cls.status === 'online');
        setDetailData(onlineClasses);
      }
    } catch (error) {
      console.error('获取在线班级详情失败:', error);
    } finally {
      setDetailLoading(false);
    }
  };

  const fetchClassesDetail = async () => {
    setDetailLoading(true);
    try {
      if (isSuperAdmin()) {
        const response = await classAPI.getAll();
        if (response.data.success) {
          setDetailData(response.data.data);
        }
      } else if (user?.schoolId) {
        const response = await schoolAPI.getClasses(user.schoolId);
        if (response.data.success) {
          setDetailData(response.data.data);
        }
      }
    } catch (error) {
      console.error('获取班级详情失败:', error);
    } finally {
      setDetailLoading(false);
    }
  };

  const fetchTeachersDetail = async () => {
    setDetailLoading(true);
    try {
      const response = await teacherAPI.getAll();
      if (response.data.success) {
        setDetailData(response.data.data);
      }
    } catch (error) {
      console.error('获取教师详情失败:', error);
    } finally {
      setDetailLoading(false);
    }
  };

  const fetchMessagesDetail = async () => {
    setDetailLoading(true);
    try {
      const response = await broadcastAPI.getMessageHistory();
      if (response.data.success) {
        setDetailData(response.data.data);
      }
    } catch (error) {
      console.error('获取消息详情失败:', error);
    } finally {
      setDetailLoading(false);
    }
  };

  // 处理仪表盘点击事件
  const handleDashboardClick = (type) => {
    // 如果已经展开，则关闭
    if (expandedCard === type) {
      setExpandedCard(null);
      return;
    }

    // 否则，展开并加载数据
    setExpandedCard(type);
    
    switch (type) {
      case 'schools':
        setDetailData(schools);
        break;
      case 'onlineClasses':
        fetchOnlineClassesDetail();
        break;
      case 'allClasses':
        fetchClassesDetail();
        break;
      case 'teachers':
        fetchTeachersDetail();
        break;
      case 'messages':
        fetchMessagesDetail();
        break;
      case 'system':
        setDetailData([{id: 1, name: '广播系统', status: '正常运行中', uptime: '99.9%'}]);
        break;
      case 'admins':
        setDetailData([{id: 1, name: '系统管理员', role: 'super_admin'}]);
        break;
      default:
        return;
    }
  };

  // 学校列表表格列
  const columns = [
    {
      title: '学校名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '联系人',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
  ];

  // 班级表格列
  const classColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '班级名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '所属学校',
      dataIndex: 'school_name',
      key: 'school_name',
    },
    {
      title: '年级',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'online' ? 'green' : status === 'offline' ? 'volcano' : 'red'}>
          {status === 'online' ? '在线' : status === 'offline' ? '离线' : '已停用'}
        </Tag>
      ),
    },
  ];

  // 消息表格列
  const messageColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '发送者',
      dataIndex: 'sender_name',
      key: 'sender_name',
    },
    {
      title: '目标班级',
      dataIndex: 'class_name',
      key: 'class_name',
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      ellipsis: true,
    },
    {
      title: '发送时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
  ];

  // 渲染详情内容
  const renderDetailContent = (type) => {
    if (detailLoading) {
      return <Spin tip="加载中..." />;
    }

    switch (type) {
      case 'schools':
        return (
          <Table 
            columns={columns} 
            dataSource={detailData.map(item => ({ ...item, key: item.id }))}
            pagination={{ pageSize: 10 }}
            bordered
            size="small"
          />
        );
      case 'onlineClasses':
      case 'allClasses':
        return (
          <Table 
            columns={classColumns} 
            dataSource={detailData.map(item => ({ ...item, key: item.id }))}
            pagination={{ pageSize: 10 }}
            bordered
            size="small"
          />
        );
      case 'teachers':
        return (
          <Table 
            columns={classColumns} 
            dataSource={detailData.map(item => ({ ...item, key: item.id }))}
            pagination={{ pageSize: 10 }}
            bordered
            size="small"
          />
        );
      case 'messages':
        return (
          <Table 
            columns={messageColumns} 
            dataSource={detailData.map(item => ({ ...item, key: item.id }))}
            pagination={{ pageSize: 10 }}
            bordered
            size="small"
          />
        );
      case 'system':
        return (
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={detailData}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.name}
                  description={`状态: ${item.status}, 可用率: ${item.uptime}`}
                />
              </List.Item>
            )}
          />
        );
      default:
        return <div>暂无详细数据</div>;
    }
  };

  // 渲染单个指标卡片
  const renderStatCard = (type, title, value, prefix, color, extra = null) => {
    const isExpanded = expandedCard === type;
    
    // 基本卡片内容
    const cardContent = (
      <>
        <Statistic
          value={value}
          prefix={prefix}
          valueStyle={{ color }}
        />
        {extra && <div style={{ marginTop: 10 }}>{extra}</div>}
      </>
    );
    
    // 展开状态的卡片
    if (isExpanded) {
      return (
        <div style={styles.expandedCardContainer}>
          <Card 
            style={styles.expandedCard}
            hoverable
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{title}</span>
                <Button 
                  type="link" 
                  icon={<UpOutlined />} 
                  onClick={() => handleDashboardClick(type)}
                  size="small"
                />
              </div>
            }
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={6}>
                {cardContent}
              </Col>
              <Col xs={24} sm={12} md={16} lg={18}>
                <Divider style={{ margin: '0 0 16px 0' }} />
                <div className="detail-content">
                  {renderDetailContent(type)}
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      );
    }
    
    // 常规状态的卡片
    return (
      <div style={styles.normalCardContainer}>
        <Card 
          style={styles.dashboardCardStyle}
          hoverable
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{title}</span>
              <Button 
                type="link" 
                icon={<DownOutlined />} 
                onClick={(e) => { e.stopPropagation(); handleDashboardClick(type); }}
                size="small"
              />
            </div>
          }
          onClick={() => handleDashboardClick(type)}
        >
          {cardContent}
        </Card>
      </div>
    );
  };

  // 主仪表盘渲染
  const renderDashboard = () => {
    if (!broadcastStats) return null;

    // 确定要显示的数据
    const totalClasses = isSuperAdmin() ? broadcastStats.totalClasses : schoolStats?.stats?.classCount || 0;
    const onlineClasses = broadcastStats.onlineClasses || 0;
    const onlineClassesPercent = totalClasses > 0 ? Math.round((onlineClasses / totalClasses) * 100) : 0;
    
    // 假设activeTeachers代表在线教师，这个数据可能需要从后端获取
    const totalTeachers = broadcastStats.totalTeachers || 0;
    const onlineTeachers = broadcastStats.activeTeachers || 0; // 这里可能需要补充后端API
    const onlineTeachersPercent = totalTeachers > 0 ? Math.round((onlineTeachers / totalTeachers) * 100) : 0;
    
    // 其他指标
    const totalSchools = isSuperAdmin() ? schools.length : 1;
    const totalMessages = isSuperAdmin() ? broadcastStats.totalMessages : schoolStats?.stats?.messageCount || 0;
    
    // 创建卡片配置数组
    const cardConfigs = [
      {
        type: 'allClasses',
        title: '班级总数',
        value: totalClasses,
        prefix: <TeamOutlined />,
        color: '#3f8600',
        extra: (
          <>
            <Text>在线班级: {onlineClasses} / {totalClasses}</Text>
            <Progress 
              percent={onlineClassesPercent} 
              status={onlineClassesPercent > 0 ? "active" : "exception"} 
            />
          </>
        )
      },
      {
        type: 'onlineClasses',
        title: '在线班级',
        value: onlineClasses,
        prefix: <WifiOutlined />,
        color: '#1890ff'
      },
      {
        type: 'teachers',
        title: '教师总数',
        value: totalTeachers,
        prefix: <AuditOutlined />,
        color: '#eb2f96',
        extra: (
          <>
            <Text>在线教师: {onlineTeachers} / {totalTeachers}</Text>
            <Progress 
              percent={onlineTeachersPercent} 
              status={onlineTeachersPercent > 0 ? "active" : "exception"} 
            />
          </>
        )
      },
      {
        type: 'messages',
        title: '广播消息',
        value: totalMessages,
        prefix: <MessageOutlined />,
        color: '#722ed1'
      },
      {
        type: 'system',
        title: '系统状态',
        value: '运行中',
        prefix: <RocketOutlined />,
        color: '#52c41a'
      },
      {
        type: 'schools',
        title: '学校总数',
        value: totalSchools,
        prefix: <BankOutlined />,
        color: '#faad14'
      }
    ];

    // 如果有展开的卡片，先渲染它
    const expandedCardConfig = cardConfigs.find(config => config.type === expandedCard);
    const normalCardConfigs = cardConfigs.filter(config => config.type !== expandedCard);
    
    return (
      <>
        {/* 渲染展开的卡片 */}
        {expandedCardConfig && renderStatCard(
          expandedCardConfig.type,
          expandedCardConfig.title,
          expandedCardConfig.value,
          expandedCardConfig.prefix,
          expandedCardConfig.color,
          expandedCardConfig.extra
        )}
        
        {/* 渲染其他未展开的卡片 */}
        <Row gutter={[16, 16]}>
          {normalCardConfigs.map(config => (
            <Col xs={24} sm={12} md={8} lg={4} key={config.type}>
              {renderStatCard(
                config.type,
                config.title,
                config.value,
                config.prefix,
                config.color,
                config.extra
              )}
            </Col>
          ))}
        </Row>

        {/* 最近消息列表保留不变 */}
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Card title="最近广播消息" extra={<Badge count={broadcastStats.recentMessages.length} style={{ backgroundColor: '#52c41a' }} />}>
              <List
                dataSource={broadcastStats.recentMessages}
                renderItem={item => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={
                        <Space>
                          <Text strong>{item.sender_name}</Text>
                          <Tag color={item.sender_type === 'admin' ? 'blue' : 'green'}>
                            {item.sender_type === 'admin' ? '管理员' : '教师'}
                          </Tag>
                          <Text type="secondary">发送至: {item.class_name}</Text>
                        </Space>
                      }
                      description={
                        <>
                          <p>{item.content}</p>
                          <Text type="secondary">{new Date(item.created_at).toLocaleString()}</Text>
                        </>
                      }
                    />
                  </List.Item>
                )}
                locale={{ emptyText: '暂无广播消息' }}
              />
            </Card>
          </Col>
        </Row>

        {/* 超级管理员才显示学校列表 */}
        {isSuperAdmin() && (
          <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card title="学校列表">
                <Table
                  columns={columns}
                  dataSource={schools.map(school => ({ ...school, key: school.id }))}
                  pagination={{ pageSize: 5 }}
                  bordered
                />
              </Card>
            </Col>
          </Row>
        )}

        {/* 学校管理员显示学校信息 */}
        {!isSuperAdmin() && schoolStats?.school && (
          <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card title="学校信息">
                <div>
                  <p><strong>学校名称:</strong> {schoolStats.school.name}</p>
                  <p><strong>地址:</strong> {schoolStats.school.address || '未设置'}</p>
                  <p><strong>联系人:</strong> {schoolStats.school.contact || '未设置'}</p>
                  <p><strong>联系电话:</strong> {schoolStats.school.phone || '未设置'}</p>
                  <p><strong>电子邮箱:</strong> {schoolStats.school.email || '未设置'}</p>
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px 0' }}>
        <Spin size="large" />
        <p style={{ marginTop: '20px' }}>加载中...</p>
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        message="错误"
        description={error}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className="dashboard">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={2}>{isSuperAdmin() ? '系统概览' : `${schoolStats?.school?.name || '学校'} 概览`}</Title>
        </Col>
      </Row>
      {renderDashboard()}
    </div>
  );
};

export default Dashboard; 