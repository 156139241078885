import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import {
  DashboardOutlined,
  BankOutlined,
  TeamOutlined,
  UserOutlined,
  ReadOutlined,
  AppstoreOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const { Sider } = Layout;

const AppSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['dashboard']);
  const location = useLocation();
  const { user } = useAuth();

  // 根据当前路径设置选中的菜单项
  useEffect(() => {
    const path = location.pathname;
    
    if (path === '/') {
      setSelectedKeys(['dashboard']);
    } else if (path.startsWith('/schools')) {
      setSelectedKeys(['schools']);
    } else if (path.startsWith('/admins')) {
      setSelectedKeys(['admins']);
    } else if (path.startsWith('/teachers')) {
      setSelectedKeys(['teachers']);
    } else if (path.startsWith('/classes')) {
      setSelectedKeys(['classes']);
    } else if (path.startsWith('/profile')) {
      setSelectedKeys(['profile']);
    } else if (path.startsWith('/broadcast')) {
      setSelectedKeys(['broadcast']);
    }
  }, [location]);

  // 判断是否显示管理员管理菜单（仅超级管理员可见）
  const showAdminMenu = user && user.role === 'super_admin';
  // 判断是否显示学校管理菜单（仅超级管理员可见）
  const showSchoolMenu = user && user.role === 'super_admin';

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      width={200}
      className="app-sidebar"
    >
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        style={{ height: '100%', borderRight: 0 }}
        theme="dark"
      >
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <Link to="/">首页</Link>
        </Menu.Item>
        
        {showSchoolMenu && (
          <Menu.Item key="schools" icon={<BankOutlined />}>
            <Link to="/schools">学校管理</Link>
          </Menu.Item>
        )}
        
        {showAdminMenu && (
          <Menu.Item key="admins" icon={<TeamOutlined />}>
            <Link to="/admins">管理员管理</Link>
          </Menu.Item>
        )}

        <Menu.Item key="teachers" icon={<ReadOutlined />}>
          <Link to="/teachers">教师管理</Link>
        </Menu.Item>
        
        <Menu.Item key="classes" icon={<AppstoreOutlined />}>
          <Link to="/classes">班级管理</Link>
        </Menu.Item>
        
        <Menu.Item key="broadcast" icon={<MessageOutlined />}>
          <Link to="/broadcast">广播管理</Link>
        </Menu.Item>
        
        <Menu.Item key="profile" icon={<UserOutlined />}>
          <Link to="/profile">个人资料</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default AppSidebar; 