import axios from 'axios';

// 创建API实例
const api = axios.create({
  baseURL: 'https://www.810086.com/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

// 请求拦截器：添加认证token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器：处理错误
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // 处理401未授权（未登录或token过期）
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// 身份认证相关API
export const authAPI = {
  login: (credentials) => api.post('/auth/login', credentials),
  register: (data) => api.post('/auth/register', data),
  profile: () => api.get('/auth/profile'),
  updateProfile: (data) => api.put('/auth/profile', data),
  changePassword: (data) => api.put('/auth/password', data)
};

// 学校相关API
export const schoolAPI = {
  getAll: () => api.get('/schools'),
  getById: (id) => api.get(`/schools/${id}`),
  create: (data) => api.post('/schools', data),
  update: (id, data) => api.put(`/schools/${id}`, data),
  delete: (id) => api.delete(`/schools/${id}`),
  getStats: (id) => api.get(`/schools/${id}/stats`),
  getClasses: (id) => api.get(`/schools/${id}/classes`),
  getTeachers: (id) => api.get(`/schools/${id}/teachers`)
};

// 管理员相关API
export const adminAPI = {
  getAll: () => api.get('/admins'),
  getById: (id) => api.get(`/admins/${id}`),
  create: (data) => api.post('/admins', data),
  update: (id, data) => api.put(`/admins/${id}`, data),
  delete: (id) => api.delete(`/admins/${id}`),
  toggleStatus: (id, status) => api.put(`/admins/${id}/status`, { status })
};

// 教师相关API
export const teacherAPI = {
  getAll: () => api.get('/teachers'),
  getById: (id) => api.get(`/teachers/${id}`),
  create: (data) => api.post('/teachers', data),
  update: (id, data) => api.put(`/teachers/${id}`, data),
  delete: (id) => api.delete(`/teachers/${id}`),
  resetToken: (id) => api.put(`/teachers/${id}/token`, {}),
  setToken: (id, token) => api.put(`/teachers/${id}/token`, { token }),
  toggleStatus: (id, status) => api.put(`/teachers/${id}/status`, { status })
};

// 班级相关API
export const classAPI = {
  getAll: () => api.get('/classes'),
  getById: (id) => api.get(`/classes/${id}`),
  create: (data) => api.post('/classes', data),
  update: (id, data) => api.put(`/classes/${id}`, data),
  delete: (id) => api.delete(`/classes/${id}`),
  resetToken: (id) => api.put(`/classes/${id}/token`, {}),
  setToken: (id, token) => api.put(`/classes/${id}/token`, { token }),
  toggleStatus: (id, status) => api.put(`/classes/${id}/status`, { status })
};

// 创建广播相关的API
export const broadcastAPI = {
  // 获取仪表盘数据
  getDashboardData: () => {
    return api.get('/broadcast/dashboard');
  },
  
  // 发送广播消息
  sendMessage: (messageData) => {
    return api.post('/broadcast/send', messageData);
  },
  
  // 获取消息历史
  getMessageHistory: (filters = {}) => {
    return api.get('/broadcast/history', {
      params: filters
    });
  },
  
  // 获取班级的消息历史
  getClassHistory: (classId) => {
    return api.get(`/broadcast/history/${classId}`);
  },
  
  // 上传文件
  uploadFile: (formData) => {
    return api.post('/broadcast/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  
  // 删除广播消息
  deleteMessage: (messageId) => {
    return api.delete(`/broadcast/message/${messageId}`);
  },
  
  // 获取所有消息发送者
  getSenders: () => {
    return api.get('/broadcast/senders');
  }
};

export default api; 