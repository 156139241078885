import React, { useState, useEffect } from 'react';
import { Card, Typography, Descriptions, Spin, Alert, Divider, Statistic, Row, Col, Button, Table, Tabs } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { schoolAPI } from '../../services/api';

const { Title } = Typography;
const { TabPane } = Tabs;

const SchoolDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [school, setSchool] = useState(null);
  const [stats, setStats] = useState(null);
  const [classes, setClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSchoolDetails();
  }, [id]);

  const fetchSchoolDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // 获取学校基本信息
      const schoolResponse = await schoolAPI.getById(id);
      if (schoolResponse.data.success) {
        setSchool(schoolResponse.data.data);
      } else {
        setError(schoolResponse.data.message || '获取学校信息失败');
        return;
      }
      
      // 获取学校统计信息
      try {
        const statsResponse = await schoolAPI.getStats(id);
        if (statsResponse.data.success) {
          setStats(statsResponse.data.data);
        }
      } catch (statsErr) {
        console.error('获取学校统计信息错误:', statsErr);
        // 不阻止加载其他信息
      }
      
      // 获取班级列表
      try {
        const classesResponse = await schoolAPI.getClasses(id);
        if (classesResponse.data.success) {
          setClasses(classesResponse.data.data);
        }
      } catch (classesErr) {
        console.error('获取班级列表错误:', classesErr);
        // 不阻止加载其他信息
      }
      
      // 获取教师列表
      try {
        const teachersResponse = await schoolAPI.getTeachers(id);
        if (teachersResponse.data.success) {
          setTeachers(teachersResponse.data.data);
        }
      } catch (teachersErr) {
        console.error('获取教师列表错误:', teachersErr);
        // 不阻止加载其他信息
      }
    } catch (err) {
      console.error('获取学校详情错误:', err);
      setError('获取学校详情失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  // 班级列表表格列
  const classColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '班级名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '年级',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => text || '-'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'active' ? 'green' : 'red' }}>
          {status === 'active' ? '正常' : '已停用'}
        </span>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => text ? new Date(text).toLocaleString() : '-'
    }
  ];

  // 教师列表表格列
  const teacherColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '学科',
      dataIndex: 'subject',
      key: 'subject',
      render: (text) => text || '-'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span style={{ color: status === 'active' ? 'green' : 'red' }}>
          {status === 'active' ? '正常' : '已停用'}
        </span>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => text ? new Date(text).toLocaleString() : '-'
    }
  ];

  return (
    <Card
      title={
        <div>
          <Button 
            type="link" 
            icon={<ArrowLeftOutlined />} 
            style={{ marginRight: 10, padding: 0 }}
            onClick={() => navigate('/schools')}
          />
          学校详情
        </div>
      }
    >
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 16 }} />}
        
        {school && (
          <>
            <Title level={4}>基本信息</Title>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="学校名称">{school.name}</Descriptions.Item>
              <Descriptions.Item label="学校ID">{school.id}</Descriptions.Item>
              <Descriptions.Item label="地址">{school.address || '未设置'}</Descriptions.Item>
              <Descriptions.Item label="联系人">{school.contact || '未设置'}</Descriptions.Item>
              <Descriptions.Item label="联系电话">{school.phone || '未设置'}</Descriptions.Item>
              <Descriptions.Item label="邮箱">{school.email || '未设置'}</Descriptions.Item>
              <Descriptions.Item label="创建时间">
                {school.created_at ? new Date(school.created_at).toLocaleString() : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="更新时间">
                {school.updated_at ? new Date(school.updated_at).toLocaleString() : '-'}
              </Descriptions.Item>
            </Descriptions>
            
            {stats && (
              <>
                <Divider />
                <Title level={4}>统计信息</Title>
                <Row gutter={16}>
                  <Col span={8}>
                    <Statistic title="班级数量" value={stats.classCount || 0} />
                  </Col>
                  <Col span={8}>
                    <Statistic title="学校管理员数量" value={stats.adminCount || 0} />
                  </Col>
                  <Col span={8}>
                    <Statistic title="教师数量" value={stats.teacherCount || 0} />
                  </Col>
                </Row>
              </>
            )}
            
            <Divider />
            <Tabs defaultActiveKey="classes">
              <TabPane tab="班级列表" key="classes">
                <Table 
                  columns={classColumns} 
                  dataSource={classes}
                  rowKey="id"
                  pagination={{ pageSize: 10 }}
                />
              </TabPane>
              <TabPane tab="教师列表" key="teachers">
                <Table 
                  columns={teacherColumns} 
                  dataSource={teachers}
                  rowKey="id"
                  pagination={{ pageSize: 10 }}
                />
              </TabPane>
            </Tabs>
          </>
        )}
      </Spin>
    </Card>
  );
};

export default SchoolDetail; 