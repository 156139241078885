import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Card, Typography, Popconfirm, message, Spin, Alert, Tag, Modal, Input, Select, Row, Col, Form } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, KeyOutlined, CopyOutlined, PoweroffOutlined, SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { classAPI, schoolAPI } from '../../services/api';

const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const ClassList = () => {
  const [classes, setClasses] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tokenModalVisible, setTokenModalVisible] = useState(false);
  const [currentClassId, setCurrentClassId] = useState(null);
  const [customToken, setCustomToken] = useState('');
  const [tokenError, setTokenError] = useState('');
  const [tokenLoading, setTokenLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    grade: '',
    schoolId: null,
    status: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      const response = await schoolAPI.getAll();
      if (response.data.success) {
        setSchools(response.data.data);
      }
    } catch (error) {
      console.error('获取学校列表失败:', error);
    }
  };

  const fetchClasses = async () => {
    try {
      setLoading(true);
      const response = await classAPI.getAll();
      if (response.data.success) {
        setClasses(response.data.data);
        setAllClasses(response.data.data);
      } else {
        setError(response.data.message || '获取班级列表失败');
      }
    } catch (err) {
      console.error('获取班级列表失败:', err);
      setError('获取班级列表失败，请检查网络连接或重新登录');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await classAPI.delete(id);
      if (response.data.success) {
        message.success('班级删除成功');
        fetchClasses();
      } else {
        message.error(response.data.message || '删除班级失败');
      }
    } catch (err) {
      console.error('删除班级失败:', err);
      message.error('删除班级失败');
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      // 切换状态 (active <-> disabled)
      const newStatus = currentStatus === 'active' ? 'disabled' : 'active';
      const response = await classAPI.toggleStatus(id, newStatus);
      
      if (response.data.success) {
        message.success(`班级${newStatus === 'active' ? '启用' : '停用'}成功`);
        fetchClasses();
      } else {
        message.error(response.data.message || `班级${newStatus === 'active' ? '启用' : '停用'}失败`);
      }
    } catch (err) {
      console.error('更新班级状态失败:', err);
      message.error('操作失败，请稍后再试');
    }
  };

  // 自动重置口令
  const handleResetToken = async (id) => {
    try {
      const response = await classAPI.resetToken(id);
      if (response.data.success) {
        message.success('班级口令重置成功');
        fetchClasses();
      } else {
        message.error(response.data.message || '重置口令失败');
      }
    } catch (err) {
      console.error('重置口令失败:', err);
      message.error('重置口令失败');
    }
  };

  // 打开手动设置口令的模态框
  const showTokenModal = (id) => {
    setCurrentClassId(id);
    setCustomToken('');
    setTokenError('');
    setTokenModalVisible(true);
  };

  // 手动设置口令
  const handleSetCustomToken = async () => {
    if (customToken.length < 3) {
      setTokenError('口令长度必须大于等于3位');
      return;
    }
    
    try {
      setTokenLoading(true);
      const response = await classAPI.setToken(currentClassId, customToken);
      if (response.data.success) {
        message.success('班级口令设置成功');
        setTokenModalVisible(false);
        fetchClasses();
      } else {
        setTokenError(response.data.message || '设置口令失败');
      }
    } catch (err) {
      console.error('设置口令失败:', err);
      setTokenError('设置口令失败，请稍后再试');
    } finally {
      setTokenLoading(false);
    }
  };

  // 复制口令到剪贴板
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('口令已复制到剪贴板');
      },
      () => {
        message.error('复制失败，请手动复制');
      }
    );
  };

  // 获取状态标签
  const getStatusTag = (status) => {
    if (status === 'online') {
      return <Tag color="green">在线</Tag>;
    } else if (status === 'offline') {
      return <Tag color="red">离线</Tag>;
    } else {
      return <Tag color="default">未知</Tag>;
    }
  };

  // 处理筛选条件变化
  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // 处理快速搜索
  const handleQuickSearch = (value) => {
    setSearchLoading(true);
    const newFilters = { ...filters, name: value };
    setFilters(newFilters);
    applyFilters(newFilters);
    setSearchLoading(false);
  };

  // 应用筛选
  const applyFilters = (currentFilters = filters) => {
    setSearchLoading(true);
    try {
      let filteredData = [...allClasses];
      
      if (currentFilters.name) {
        const searchTerm = currentFilters.name.toLowerCase();
        filteredData = filteredData.filter(cls => 
          cls.name.toLowerCase().includes(searchTerm)
        );
      }

      if (currentFilters.grade) {
        const gradeSearchTerm = currentFilters.grade.toLowerCase();
        filteredData = filteredData.filter(cls => 
          cls.grade && cls.grade.toLowerCase().includes(gradeSearchTerm)
        );
      }

      if (currentFilters.schoolId) {
        filteredData = filteredData.filter(cls => 
          cls.school_id === currentFilters.schoolId
        );
      }

      if (currentFilters.status) {
        filteredData = filteredData.filter(cls => 
          cls.status === currentFilters.status
        );
      }

      setClasses(filteredData);
    } finally {
      setSearchLoading(false);
    }
  };

  // 重置筛选
  const resetFilters = () => {
    setFilters({
      name: '',
      grade: '',
      schoolId: null,
      status: null,
    });
    setClasses(allClasses);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '班级名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '年级',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => text || '-'
    },
    {
      title: '所属学校',
      dataIndex: 'school_name',
      key: 'school_name',
    },
    {
      title: '班级口令',
      dataIndex: 'token',
      key: 'token',
      render: (token) => (
        <Space>
          <Text copyable>{token}</Text>
          <Button 
            type="link" 
            icon={<CopyOutlined />} 
            size="small" 
            onClick={() => copyToClipboard(token)}
          />
        </Space>
      )
    },
    {
      title: '使用权限',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'red'}>
          {status === 'active' ? '正常' : '已停用'}
        </Tag>
      )
    },
    {
      title: '在线状态',
      dataIndex: 'status',
      key: 'online_status',
      render: (status) => (
        <Tag color={status === 'online' ? 'green' : 'gray'}>
          {status === 'online' ? '在线' : '离线'}
        </Tag>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => text ? new Date(text).toLocaleString() : '-'
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="primary" 
            icon={<EditOutlined />} 
            size="small"
            onClick={() => navigate(`/classes/edit/${record.id}`)}
          >
            编辑
          </Button>
          
          <Button 
            type={record.status === 'active' ? 'danger' : 'primary'}
            icon={<PoweroffOutlined />} 
            size="small"
            onClick={() => handleStatusToggle(record.id, record.status)}
          >
            {record.status === 'active' ? '停用' : '启用'}
          </Button>
          
          <Button 
            type="primary" 
            icon={<KeyOutlined />} 
            size="small"
            onClick={() => showTokenModal(record.id)}
          >
            设置口令
          </Button>
          
          <Popconfirm
            title="确定要删除这个班级吗?"
            onConfirm={() => handleDelete(record.id)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} size="small">
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title level={2}>班级管理</Title>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={() => navigate('/classes/create')}
            >
              添加班级
            </Button>
          </div>
          
          {/* 筛选区域 */}
          <div style={{ background: '#f6f6f6', padding: '15px', borderRadius: '5px' }}>
            <Row gutter={16}>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="班级名称" style={{ marginBottom: 0 }}>
                  <Search
                    placeholder="搜索班级名称"
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                    onSearch={handleQuickSearch}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="年级" style={{ marginBottom: 0 }}>
                  <Input
                    placeholder="搜索年级"
                    value={filters.grade}
                    onChange={(e) => handleFilterChange('grade', e.target.value)}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="所属学校" style={{ marginBottom: 0 }}>
                  <Select
                    placeholder="选择学校"
                    value={filters.schoolId}
                    onChange={(value) => handleFilterChange('schoolId', value)}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {schools.map(school => (
                      <Option key={school.id} value={school.id}>{school.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="使用权限" style={{ marginBottom: 0 }}>
                  <Select
                    placeholder="选择状态"
                    value={filters.status}
                    onChange={(value) => handleFilterChange('status', value)}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    <Option value="active">正常</Option>
                    <Option value="disabled">已停用</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Space>
                  <Button 
                    icon={<ClearOutlined />} 
                    onClick={resetFilters}
                  >
                    重置
                  </Button>
                  <Button 
                    type="primary" 
                    icon={<SearchOutlined />} 
                    onClick={() => applyFilters()}
                    loading={searchLoading}
                  >
                    筛选
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
          
          {error && <Alert message={error} type="error" showIcon />}
          
          <Spin spinning={loading}>
            <Table 
              columns={columns} 
              dataSource={classes}
              rowKey="id"
              pagination={{ pageSize: 10 }}
            />
          </Spin>
        </Space>
      </Card>
      
      {/* 手动设置口令的模态框 */}
      <Modal
        title="设置班级口令"
        open={tokenModalVisible}
        onCancel={() => setTokenModalVisible(false)}
        footer={[
          <Button key="reset" onClick={() => handleResetToken(currentClassId)}>
            自动生成口令
          </Button>,
          <Button key="cancel" onClick={() => setTokenModalVisible(false)}>
            取消
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            loading={tokenLoading}
            onClick={handleSetCustomToken}
          >
            确定
          </Button>,
        ]}
      >
        <div style={{ marginBottom: 16 }}>
          <p>请输入新的班级口令（至少3位字符）：</p>
          <Input
            value={customToken}
            onChange={(e) => setCustomToken(e.target.value)}
            placeholder="请输入新的班级口令"
          />
          {tokenError && <div style={{ color: 'red', marginTop: 8 }}>{tokenError}</div>}
        </div>
      </Modal>
    </>
  );
};

export default ClassList; 