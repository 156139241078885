import React, { useState } from 'react';
import { Card, Tabs, Form, Input, Button, message, Alert, Descriptions, Divider, Typography } from 'antd';
import { UserOutlined, LockOutlined, SaveOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import { authAPI } from '../services/api';

const { Title } = Typography;

const Profile = () => {
  const { user } = useAuth();
  const [passwordForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 处理修改密码
  const handleChangePassword = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('两次输入的新密码不一致');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await authAPI.changePassword(values.currentPassword, values.newPassword);
      if (response.data.success) {
        message.success('密码修改成功');
        passwordForm.resetFields();
      } else {
        setError(response.data.message || '密码修改失败');
      }
    } catch (err) {
      console.error('修改密码错误:', err);
      setError(err.response?.data?.message || '密码修改失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  const items = [
    {
      label: '个人信息',
      key: 'info',
      children: (
        <div>
          <Descriptions
            bordered
            column={1}
            labelStyle={{ width: '150px', fontWeight: 'bold' }}
          >
            <Descriptions.Item label="用户名">{user?.username || '-'}</Descriptions.Item>
            <Descriptions.Item label="姓名">{user?.name || '-'}</Descriptions.Item>
            <Descriptions.Item label="邮箱">{user?.email || '-'}</Descriptions.Item>
            <Descriptions.Item label="角色">
              {user?.role === 'super_admin' ? '超级管理员' : '学校管理员'}
            </Descriptions.Item>
            {user?.role === 'school_admin' && (
              <Descriptions.Item label="所属学校ID">{user?.schoolId || '-'}</Descriptions.Item>
            )}
          </Descriptions>

          <Divider />

          <p style={{ color: '#888' }}>
            * 如需修改个人信息，请联系系统管理员
          </p>
        </div>
      ),
    },
    {
      label: '修改密码',
      key: 'password',
      children: (
        <div>
          {error && (
            <Alert
              message="错误"
              description={error}
              type="error"
              showIcon
              style={{ marginBottom: '20px' }}
            />
          )}

          <Form
            form={passwordForm}
            layout="vertical"
            onFinish={handleChangePassword}
          >
            <Form.Item
              name="currentPassword"
              label="当前密码"
              rules={[{ required: true, message: '请输入当前密码' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="当前密码"
              />
            </Form.Item>

            <Form.Item
              name="newPassword"
              label="新密码"
              rules={[
                { required: true, message: '请输入新密码' },
                { min: 6, message: '密码长度至少6个字符' }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="新密码"
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="确认新密码"
              rules={[
                { required: true, message: '请再次输入新密码' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次输入的密码不一致'));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="确认新密码"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                loading={loading}
              >
                保存修改
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>个人资料</Title>
      <Card>
        <Tabs defaultActiveKey="info" items={items} />
      </Card>
    </div>
  );
};

export default Profile; 