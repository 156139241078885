import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Spin, Alert } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { teacherAPI } from '../../services/api';

const { Option } = Select;

const TeacherEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTeacherDetails();
  }, [id]);

  const fetchTeacherDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // 获取教师详情
      const response = await teacherAPI.getById(id);
      if (response.data.success) {
        const teacherData = response.data.data;
        form.setFieldsValue({
          name: teacherData.name,
          subject: teacherData.subject,
          email: teacherData.email,
          phone: teacherData.phone
        });
      } else {
        setError(response.data.message || '获取教师信息失败');
      }
    } catch (err) {
      console.error('获取教师信息错误:', err);
      setError('获取教师信息失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await teacherAPI.update(id, values);
      if (response.data.success) {
        message.success('教师信息更新成功');
        navigate('/teachers');
      } else {
        message.error(response.data.message || '更新教师信息失败');
      }
    } catch (err) {
      console.error('更新教师信息错误:', err);
      message.error('更新教师信息失败，请稍后再试');
    }
  };

  return (
    <Card title="编辑教师">
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 24 }} />}
        
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '请输入姓名' }]}
          >
            <Input placeholder="请输入姓名" />
          </Form.Item>

          <Form.Item
            label="学科"
            name="subject"
          >
            <Input placeholder="请输入学科" />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { type: 'email', message: '请输入有效的邮箱地址' }
            ]}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>

          <Form.Item
            label="联系电话"
            name="phone"
          >
            <Input placeholder="请输入联系电话" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate('/teachers')}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default TeacherEdit; 