import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Spin, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const { Option } = Select;

const TeacherCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [schools, setSchools] = useState([]);
  const { user, isSuperAdmin } = useAuth();
  
  // 获取学校列表 (只有超级管理员需要)
  useEffect(() => {
    if (isSuperAdmin()) {
      fetchSchools();
    } else if (user && user.schoolId) {
      // 学校管理员，设置默认学校ID
      form.setFieldsValue({
        schoolId: user.schoolId
      });
    }
  }, [form, user, isSuperAdmin]);

  const fetchSchools = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/schools', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setSchools(response.data.data || []);
      setError(null);
    } catch (err) {
      console.error('获取学校列表失败:', err);
      setError('获取学校列表失败，请检查网络连接或重新登录');
    } finally {
      setLoading(false);
    }
  };

  // 表单提交
  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      
      // 如果是学校管理员，使用管理员所属学校ID
      if (!isSuperAdmin() && user && user.schoolId) {
        values.schoolId = user.schoolId;
      }
      
      const response = await axios.post('/api/teachers', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      message.success('教师创建成功');
      
      // 显示教师口令
      if (response.data?.data?.token) {
        message.info(`教师登录口令: ${response.data.data.token}`);
      }
      
      // 返回列表页
      navigate('/teachers');
    } catch (err) {
      console.error('创建教师失败:', err);
      setError('创建教师失败: ' + (err.response?.data?.message || err.message));
      setSubmitting(false);
    }
  };

  return (
    <Card title="添加教师">
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 16 }} />}
        
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="教师姓名"
            rules={[{ required: true, message: '请输入教师姓名' }]}
          >
            <Input placeholder="请输入教师姓名" />
          </Form.Item>
          
          <Form.Item
            name="subject"
            label="任教科目"
          >
            <Input placeholder="请输入任教科目" />
          </Form.Item>
          
          {isSuperAdmin() ? (
            <Form.Item
              name="schoolId"
              label="所属学校"
              rules={[{ required: true, message: '请选择所属学校' }]}
            >
              <Select placeholder="请选择所属学校">
                {schools.map(school => (
                  <Option key={school.id} value={school.id}>{school.name}</Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              name="schoolId"
              label="所属学校"
              hidden
            >
              <Input type="hidden" />
            </Form.Item>
          )}
          
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              创建教师
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate('/teachers')}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default TeacherCreate; 