import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Card, Typography, Popconfirm, message, Spin, Alert, Tag, Modal, Input, Select, Row, Col, Form } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, KeyOutlined, CopyOutlined, PoweroffOutlined, SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { teacherAPI, schoolAPI } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const TeacherList = () => {
  const [teachers, setTeachers] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isSuperAdmin } = useAuth();
  const [tokenModalVisible, setTokenModalVisible] = useState(false);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [customToken, setCustomToken] = useState('');
  const [tokenError, setTokenError] = useState('');
  const [tokenLoading, setTokenLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    subject: '',
    schoolId: null,
    status: null,
  });

  useEffect(() => {
    fetchTeachers();
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    try {
      const response = await schoolAPI.getAll();
      if (response.data.success) {
        setSchools(response.data.data);
      }
    } catch (error) {
      console.error('获取学校列表失败:', error);
    }
  };

  const fetchTeachers = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await teacherAPI.getAll();
      if (response.data.success) {
        setTeachers(response.data.data);
        setAllTeachers(response.data.data);
      } else {
        setError(response.data.message || '获取教师列表失败');
      }
    } catch (err) {
      console.error('获取教师列表错误:', err);
      setError('获取教师列表失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await teacherAPI.delete(id);
      if (response.data.success) {
        message.success('教师删除成功');
        fetchTeachers();
      } else {
        message.error(response.data.message || '删除教师失败');
      }
    } catch (err) {
      console.error('删除教师错误:', err);
      message.error('删除教师失败，请稍后再试');
    }
  };

  const handleResetToken = async (id) => {
    try {
      const response = await teacherAPI.resetToken(id);
      if (response.data.success) {
        message.success('教师口令重置成功');
        fetchTeachers();
      } else {
        message.error(response.data.message || '重置口令失败');
      }
    } catch (err) {
      console.error('重置口令失败:', err);
      message.error('重置口令失败');
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 'active' ? 'disabled' : 'active';
      const response = await teacherAPI.toggleStatus(id, newStatus);
      
      if (response.data.success) {
        message.success(`教师${newStatus === 'active' ? '启用' : '停用'}成功`);
        fetchTeachers();
      } else {
        message.error(response.data.message || `教师${newStatus === 'active' ? '启用' : '停用'}失败`);
      }
    } catch (err) {
      console.error('更新教师状态失败:', err);
      message.error('操作失败，请稍后再试');
    }
  };
  
  const showTokenModal = (id) => {
    setCurrentTeacherId(id);
    setCustomToken('');
    setTokenError('');
    setTokenModalVisible(true);
  };

  const handleSetCustomToken = async () => {
    if (customToken.length < 3) {
      setTokenError('口令长度必须大于等于3位');
      return;
    }
    
    try {
      setTokenLoading(true);
      const response = await teacherAPI.setToken(currentTeacherId, customToken);
      if (response.data.success) {
        message.success('教师口令设置成功');
        setTokenModalVisible(false);
        fetchTeachers();
      } else {
        setTokenError(response.data.message || '设置口令失败');
      }
    } catch (err) {
      console.error('设置口令失败:', err);
      setTokenError('设置口令失败，请稍后再试');
    } finally {
      setTokenLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('口令已复制到剪贴板');
      },
      () => {
        message.error('复制失败，请手动复制');
      }
    );
  };

  // 处理筛选条件变化
  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // 处理快速搜索
  const handleQuickSearch = (value) => {
    setSearchLoading(true);
    const newFilters = { ...filters, name: value };
    setFilters(newFilters);
    applyFilters(newFilters);
    setSearchLoading(false);
  };

  // 应用筛选
  const applyFilters = (currentFilters = filters) => {
    setSearchLoading(true);
    try {
      let filteredData = [...allTeachers];
      
      if (currentFilters.name) {
        const searchTerm = currentFilters.name.toLowerCase();
        filteredData = filteredData.filter(teacher => 
          teacher.name.toLowerCase().includes(searchTerm)
        );
      }

      if (currentFilters.subject) {
        const subjectSearchTerm = currentFilters.subject.toLowerCase();
        filteredData = filteredData.filter(teacher => 
          teacher.subject && teacher.subject.toLowerCase().includes(subjectSearchTerm)
        );
      }

      if (currentFilters.schoolId) {
        filteredData = filteredData.filter(teacher => 
          teacher.school_id === currentFilters.schoolId
        );
      }

      if (currentFilters.status) {
        filteredData = filteredData.filter(teacher => 
          teacher.status === currentFilters.status
        );
      }

      setTeachers(filteredData);
    } finally {
      setSearchLoading(false);
    }
  };

  // 重置筛选
  const resetFilters = () => {
    setFilters({
      name: '',
      subject: '',
      schoolId: null,
      status: null,
    });
    setTeachers(allTeachers);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '学科',
      dataIndex: 'subject',
      key: 'subject',
      render: (text) => text || '-'
    },
    {
      title: '所属学校',
      dataIndex: 'school_name',
      key: 'school_name',
    },
    {
      title: '登录口令',
      dataIndex: 'token',
      key: 'token',
      render: (token) => (
        <Space>
          <Text copyable>{token}</Text>
          <Button 
            type="link" 
            icon={<CopyOutlined />} 
            size="small" 
            onClick={() => copyToClipboard(token)}
          />
        </Space>
      )
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'red'}>
          {status === 'active' ? '正常' : '已停用'}
        </Tag>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => text ? new Date(text).toLocaleString() : '-'
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="primary" 
            icon={<EditOutlined />} 
            size="small"
            onClick={() => navigate(`/teachers/${record.id}/edit`)}
          >
            编辑
          </Button>
          
          <Button 
            type={record.status === 'active' ? 'danger' : 'primary'}
            icon={<PoweroffOutlined />} 
            size="small"
            onClick={() => handleStatusToggle(record.id, record.status)}
          >
            {record.status === 'active' ? '停用' : '启用'}
          </Button>
          
          <Button 
            type="primary" 
            icon={<KeyOutlined />} 
            size="small"
            onClick={() => showTokenModal(record.id)}
          >
            设置口令
          </Button>
          
          <Button 
            type="primary" 
            onClick={() => navigate(`/teachers/bind-classes/${record.id}`)}
            size="small"
          >
            班级绑定
          </Button>
          
          <Popconfirm
            title="确定要删除这个教师吗?"
            onConfirm={() => handleDelete(record.id)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} size="small">
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title level={2}>教师管理</Title>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={() => navigate('/teachers/create')}
            >
              添加教师
            </Button>
          </div>
          
          {/* 筛选区域 */}
          <div style={{ background: '#f6f6f6', padding: '15px', borderRadius: '5px' }}>
            <Row gutter={16}>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="教师姓名" style={{ marginBottom: 0 }}>
                  <Search
                    placeholder="搜索教师姓名"
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                    onSearch={handleQuickSearch}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="学科" style={{ marginBottom: 0 }}>
                  <Input
                    placeholder="搜索学科"
                    value={filters.subject}
                    onChange={(e) => handleFilterChange('subject', e.target.value)}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="所属学校" style={{ marginBottom: 0 }}>
                  <Select
                    placeholder="选择学校"
                    value={filters.schoolId}
                    onChange={(value) => handleFilterChange('schoolId', value)}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {schools.map(school => (
                      <Option key={school.id} value={school.id}>{school.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={8} lg={6}>
                <Form.Item label="教师状态" style={{ marginBottom: 0 }}>
                  <Select
                    placeholder="选择状态"
                    value={filters.status}
                    onChange={(value) => handleFilterChange('status', value)}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    <Option value="active">正常</Option>
                    <Option value="disabled">已停用</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Space>
                  <Button 
                    icon={<ClearOutlined />} 
                    onClick={resetFilters}
                  >
                    重置
                  </Button>
                  <Button 
                    type="primary" 
                    icon={<SearchOutlined />} 
                    onClick={() => applyFilters()}
                    loading={searchLoading}
                  >
                    筛选
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
          
          {error && <Alert message={error} type="error" showIcon />}
          
          <Spin spinning={loading}>
            <Table 
              columns={columns} 
              dataSource={teachers}
              rowKey="id"
              pagination={{ pageSize: 10 }}
            />
          </Spin>
        </Space>
      </Card>
      
      <Modal
        title="设置教师口令"
        open={tokenModalVisible}
        onCancel={() => setTokenModalVisible(false)}
        footer={[
          <Button key="reset" onClick={() => handleResetToken(currentTeacherId)}>
            自动生成口令
          </Button>,
          <Button key="cancel" onClick={() => setTokenModalVisible(false)}>
            取消
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            loading={tokenLoading}
            onClick={handleSetCustomToken}
          >
            确定
          </Button>,
        ]}
      >
        <div style={{ marginBottom: 16 }}>
          <p>请输入新的教师登录口令（至少3位字符）：</p>
          <Input
            value={customToken}
            onChange={(e) => setCustomToken(e.target.value)}
            placeholder="请输入新的教师登录口令"
          />
          {tokenError && <div style={{ color: 'red', marginTop: 8 }}>{tokenError}</div>}
        </div>
      </Modal>
    </>
  );
};

export default TeacherList; 