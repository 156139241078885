import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, message, Spin, Alert } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { classAPI, schoolAPI } from '../../services/api';

const { Option } = Select;

const ClassEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [classInfo, setClassInfo] = useState(null);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      // 获取班级详情
      const classResponse = await classAPI.getById(id);
      if (!classResponse.data.success) {
        setError(classResponse.data.message || '获取班级信息失败');
        return;
      }
      
      const classData = classResponse.data.data;
      setClassInfo(classData);
      
      // 获取学校列表
      const schoolsResponse = await schoolAPI.getAll();
      if (schoolsResponse.data.success) {
        setSchools(schoolsResponse.data.data);
      }
      
      // 设置表单初始值
      form.setFieldsValue({
        name: classData.name,
        grade: classData.grade,
        schoolId: classData.school_id
      });
    } catch (err) {
      console.error('获取数据失败:', err);
      setError('获取数据失败，请检查网络连接或重新登录');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      setError(null);

      const response = await classAPI.update(id, values);
      if (response.data.success) {
        message.success('班级更新成功');
        navigate('/classes');
      } else {
        setError(response.data.message || '更新班级失败');
      }
    } catch (err) {
      console.error('更新班级失败:', err);
      setError('更新班级失败，请检查网络连接或重新登录');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card title={
      <div>
        <Button 
          type="link" 
          icon={<ArrowLeftOutlined />} 
          style={{ marginRight: 10, padding: 0 }}
          onClick={() => navigate('/classes')}
        />
        编辑班级
      </div>
    }>
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 16 }} />}
        
        {classInfo && (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="班级名称"
              rules={[{ required: true, message: '请输入班级名称' }]}
            >
              <Input placeholder="请输入班级名称" />
            </Form.Item>
            
            <Form.Item
              name="grade"
              label="年级"
            >
              <Input placeholder="请输入年级" />
            </Form.Item>
            
            <Form.Item
              name="schoolId"
              label="所属学校"
              rules={[{ required: true, message: '请选择所属学校' }]}
            >
              <Select placeholder="请选择所属学校">
                {schools.map(school => (
                  <Option key={school.id} value={school.id}>{school.name}</Option>
                ))}
              </Select>
            </Form.Item>
            
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitting}>
                保存修改
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={() => navigate('/classes')}>
                取消
              </Button>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Card>
  );
};

export default ClassEdit; 