import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Card, Typography, Popconfirm, message, Spin, Alert, Tag } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, PoweroffOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { adminAPI } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

const { Title } = Typography;

const AdminList = () => {
  const navigate = useNavigate();
  const { user, isSuperAdmin } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [admins, setAdmins] = useState([]);

  // 获取管理员列表
  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await adminAPI.getAll();
      if (response.data.success) {
        setAdmins(response.data.data);
      } else {
        setError(response.data.message || '获取管理员列表失败');
      }
    } catch (err) {
      console.error('获取管理员列表错误:', err);
      setError('获取管理员列表失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  // 删除管理员
  const handleDelete = async (id) => {
    try {
      const response = await adminAPI.delete(id);
      if (response.data.success) {
        message.success('管理员删除成功');
        // 重新获取管理员列表
        fetchAdmins();
      } else {
        message.error(response.data.message || '删除管理员失败');
      }
    } catch (err) {
      console.error('删除管理员错误:', err);
      message.error(err.response?.data?.message || '删除管理员失败，请稍后再试');
    }
  };

  // 切换管理员状态
  const handleStatusToggle = async (id, currentStatus) => {
    try {
      // 切换状态 (active <-> disabled)
      const newStatus = currentStatus === 'active' ? 'disabled' : 'active';
      const response = await adminAPI.toggleStatus(id, newStatus);
      
      if (response.data.success) {
        message.success(`管理员${newStatus === 'active' ? '启用' : '停用'}成功`);
        fetchAdmins();
      } else {
        message.error(response.data.message || `管理员${newStatus === 'active' ? '启用' : '停用'}失败`);
      }
    } catch (err) {
      console.error('更新管理员状态失败:', err);
      message.error(err.response?.data?.message || '操作失败，请稍后再试');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: (text) => text || '-'
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        if (role === 'super_admin') {
          return <Tag color="volcano">超级管理员</Tag>;
        } else if (role === 'school_admin') {
          return <Tag color="green">学校管理员</Tag>;
        }
        return <Tag color="default">{role}</Tag>;
      }
    },
    {
      title: '所属学校',
      dataIndex: 'school_name',
      key: 'school_name',
      render: (text) => text || '-'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'red'}>
          {status === 'active' ? '正常' : '已停用'}
        </Tag>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => text ? new Date(text).toLocaleString() : '-'
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        // 不能操作超级管理员和自己
        const canOperate = record.role !== 'super_admin' && record.id !== user?.id;
        
        return (
          <Space size="middle">
            {canOperate && (
              <>
                <Button 
                  type="primary" 
                  icon={<EditOutlined />} 
                  size="small"
                  onClick={() => navigate(`/admins/${record.id}/edit`)}
                >
                  编辑
                </Button>
                
                <Button 
                  type={record.status === 'active' ? 'danger' : 'primary'}
                  icon={<PoweroffOutlined />} 
                  size="small"
                  onClick={() => handleStatusToggle(record.id, record.status)}
                >
                  {record.status === 'active' ? '停用' : '启用'}
                </Button>
                
                <Popconfirm
                  title="确定要删除这个管理员吗?"
                  onConfirm={() => handleDelete(record.id)}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type="primary" danger icon={<DeleteOutlined />} size="small">
                    删除
                  </Button>
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Card
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title level={4} style={{ margin: 0 }}>管理员管理</Title>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/admins/create')}
          >
            添加管理员
          </Button>
        </div>
      }
    >
      {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 16 }} />}
      
      <Table
        columns={columns}
        dataSource={admins}
        rowKey="id"
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `共 ${total} 条记录`
        }}
      />
    </Card>
  );
};

export default AdminList; 