import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Spin, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const { Option } = Select;

const AdminCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { isSuperAdmin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [schools, setSchools] = useState([]);
  
  // 获取学校列表（仅超级管理员需要）
  useEffect(() => {
    if (isSuperAdmin()) {
      const fetchSchools = async () => {
        try {
          setLoading(true);
          const response = await axios.get('/api/schools', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          setSchools(response.data.data || []);
          setError(null);
        } catch (err) {
          console.error('获取学校列表失败:', err);
          setError('获取学校列表失败，请检查网络连接或重新登录');
        } finally {
          setLoading(false);
        }
      };

      fetchSchools();
    }
  }, [isSuperAdmin]);

  // 表单提交
  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      const response = await axios.post('/api/admins', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      message.success('管理员创建成功');
      
      // 返回列表页
      navigate('/admins');
    } catch (err) {
      console.error('创建管理员失败:', err);
      setError('创建管理员失败: ' + (err.response?.data?.message || err.message));
      setSubmitting(false);
    }
  };

  return (
    <Card title="添加管理员">
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 16 }} />}
        
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            label="用户名"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>
          
          <Form.Item
            name="name"
            label="姓名"
            rules={[{ required: true, message: '请输入姓名' }]}
          >
            <Input placeholder="请输入姓名" />
          </Form.Item>
          
          <Form.Item
            name="password"
            label="密码"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          
          {isSuperAdmin() && (
            <Form.Item
              name="role"
              label="角色"
              rules={[{ required: true, message: '请选择角色' }]}
              initialValue="school_admin"
            >
              <Select placeholder="请选择角色">
                <Option value="super_admin">超级管理员</Option>
                <Option value="school_admin">学校管理员</Option>
              </Select>
            </Form.Item>
          )}
          
          {isSuperAdmin() && (
            <Form.Item
              name="schoolId"
              label="所属学校"
              rules={[
                { required: true, message: '请选择所属学校' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('role') === 'super_admin' || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('学校管理员必须选择所属学校'));
                  },
                }),
              ]}
            >
              <Select placeholder="请选择所属学校">
                {schools.map(school => (
                  <Option key={school.id} value={school.id}>{school.name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              创建管理员
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate('/admins')}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default AdminCreate; 