import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

// 创建认证上下文
const AuthContext = createContext();

// API基础URL
const API_URL = 'https://www.810086.com/api';

// 认证提供者组件
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // 初始化验证用户登录状态
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchCurrentUser(token);
    } else {
      setLoading(false);
    }
  }, []);

  // 获取当前用户信息
  const fetchCurrentUser = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/auth/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.success) {
        setUser(response.data.data);
        setIsAuthenticated(true);
      } else {
        // 如果验证失败，清除token
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error('获取用户信息失败:', error);
      // 出错时清除token
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  // 登录
  const login = async (username, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, { username, password });
      
      if (response.data.success) {
        const { token, user } = response.data.data;
        localStorage.setItem('token', token);
        setUser(user);
        setIsAuthenticated(true);
        return { success: true, user };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('登录失败:', error);
      return { 
        success: false, 
        message: error.response?.data?.message || '登录失败，请稍后重试' 
      };
    }
  };

  // 注销
  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
  };

  // 检查是否是超级管理员
  const isSuperAdmin = () => {
    return isAuthenticated && user && user.role === 'super_admin';
  };

  // 检查是否是学校管理员
  const isSchoolAdmin = () => {
    return isAuthenticated && user && user.role === 'school_admin';
  };

  // 提供的上下文值
  const contextValue = {
    user,
    isAuthenticated,
    loading,
    login,
    logout,
    isSuperAdmin,
    isSchoolAdmin
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// 自定义hook，用于在组件中访问认证上下文
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext; 