import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import './App.css';

// 导入页面组件
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import SchoolList from './pages/schools/SchoolList';
import SchoolCreate from './pages/schools/SchoolCreate';
import SchoolEdit from './pages/schools/SchoolEdit';
import SchoolDetail from './pages/schools/SchoolDetail';
import AdminList from './pages/admins/AdminList';
import AdminCreate from './pages/admins/AdminCreate';
import AdminEdit from './pages/admins/AdminEdit';
import TeacherList from './pages/teachers/TeacherList';
import TeacherCreate from './pages/teachers/TeacherCreate';
import TeacherEdit from './pages/teachers/TeacherEdit';
import TeacherClassBinding from './pages/teachers/TeacherClassBinding';
import ClassList from './pages/classes/ClassList';
import ClassCreate from './pages/classes/ClassCreate';
import ClassEdit from './pages/classes/ClassEdit';
import BroadcastList from './pages/broadcast/BroadcastList';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';

// 导入布局组件
import AppHeader from './components/layout/AppHeader';
import AppSidebar from './components/layout/AppSidebar';

// 导入认证上下文
import { AuthProvider, useAuth } from './contexts/AuthContext';

const { Content } = Layout;

// 保护路由的高阶组件
const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return isAuthenticated ? children : <Navigate to="/login" />;
};

// 主应用布局
const AppLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader />
      <Layout>
        <AppSidebar />
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          <Route 
            path="/" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <Dashboard />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          {/* 学校管理路由 */}
          <Route 
            path="/schools" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <SchoolList />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/schools/create" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <SchoolCreate />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/schools/:id" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <SchoolDetail />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/schools/:id/edit" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <SchoolEdit />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          {/* 管理员管理路由 */}
          <Route 
            path="/admins" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <AdminList />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/admins/create" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <AdminCreate />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/admins/:id/edit" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <AdminEdit />
                </AppLayout>
              </PrivateRoute>
            } 
          />

          {/* 教师管理路由 */}
          <Route 
            path="/teachers" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <TeacherList />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/teachers/create" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <TeacherCreate />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/teachers/:id/edit" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <TeacherEdit />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/teachers/bind-classes/:id" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <TeacherClassBinding />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          {/* 班级管理路由 */}
          <Route 
            path="/classes" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <ClassList />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/classes/create" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <ClassCreate />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          <Route 
            path="/classes/edit/:id" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <ClassEdit />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          {/* 广播管理路由 */}
          <Route 
            path="/broadcast" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <BroadcastList />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          {/* 个人资料路由 */}
          <Route 
            path="/profile" 
            element={
              <PrivateRoute>
                <AppLayout>
                  <Profile />
                </AppLayout>
              </PrivateRoute>
            } 
          />
          
          {/* 404页面 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
