import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, message, Spin, Alert } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { schoolAPI } from '../../services/api';

const SchoolEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSchoolDetails();
  }, [id]);

  const fetchSchoolDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // 获取学校详情
      const response = await schoolAPI.getById(id);
      if (response.data.success) {
        const schoolData = response.data.data;
        form.setFieldsValue({
          name: schoolData.name,
          address: schoolData.address,
          contact: schoolData.contact,
          phone: schoolData.phone,
          email: schoolData.email
        });
      } else {
        setError(response.data.message || '获取学校信息失败');
      }
    } catch (err) {
      console.error('获取学校信息错误:', err);
      setError('获取学校信息失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await schoolAPI.update(id, values);
      if (response.data.success) {
        message.success('学校信息更新成功');
        navigate('/schools');
      } else {
        message.error(response.data.message || '更新学校信息失败');
      }
    } catch (err) {
      console.error('更新学校信息错误:', err);
      message.error('更新学校信息失败，请稍后再试');
    }
  };

  return (
    <Card title="编辑学校">
      <Spin spinning={loading}>
        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 24 }} />}
        
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="学校名称"
            name="name"
            rules={[{ required: true, message: '请输入学校名称' }]}
          >
            <Input placeholder="请输入学校名称" />
          </Form.Item>

          <Form.Item
            label="地址"
            name="address"
          >
            <Input placeholder="请输入学校地址" />
          </Form.Item>

          <Form.Item
            label="联系人"
            name="contact"
          >
            <Input placeholder="请输入联系人姓名" />
          </Form.Item>

          <Form.Item
            label="联系电话"
            name="phone"
          >
            <Input placeholder="请输入联系电话" />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { type: 'email', message: '请输入有效的邮箱地址' }
            ]}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={() => navigate('/schools')}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default SchoolEdit; 